export default {
  header: {
    home: '首页',
    product: '公司产品',
    solution: '行业解决方案',
    job: '招聘精英',
    about: '关于我们'
  },
  footer: {
    partner: '合作伙伴',
    copyright: '2013-2024  北京惠鑫天空科技有限公司  All rights reserved.',
    icp: '京ICP备2023012569号',
    beian: '京公网安备 11011302005703号'
  },
  info: {
    address: '公司地址： ',
    email: '公司邮箱： '
  },
  company: {
    title: '北京惠鑫天空科技有限公司',
    content:
      '北京惠鑫天空科技有限公司，成立于2013年12月5日，是国内率先进行“数据资源一体化智能管理平台”的平台研发企业。平台主要针对可信空间和数据互联互通具有重要的推动和保障作用，实现多介质资源的统一和共享，优化数据跨介质的流转和存储，构建开放互通的生态系统平台，彻底解决数据跨平台和异构环境的兼容性问题。'
  },
  button: {
    more: '查看更多',
    detail: '查看详情'
  },
  sectionTitle: {
    profile: '公司介绍',
    profileEn: 'Company Introduction',
    product: '公司产品',
    productEn: 'Company Products',
    solution: '行业解决方案',
    solutionEn: 'Industry Solutions',
    dynamics: '公司动态',
    dynamicsEn: 'Corporate News',
    /* history: '发展历程',
    historyEn: 'Development Process', */
    culture: '企业文化',
    cultureEn: 'Corporate Culture',
    major: '主打产品',
    majorEn: 'Flagship Products',
    allProducts: '全部产品',
    allProductsEn: 'All Products',
    businessValue: '商业价值',
    businessValueEn: 'Business Value',
    case: '合作案例',
    caseEn: 'Cooperation Cases',
    job: '招聘精英',
    jobEn: 'Recruitment',
    license: '知识产权',
    licenseEn: 'License',
    software: '软件产品',
    hardware: '硬件产品'
  },
  error: {
    notFound: {
      text: '很抱歉，您要访问的页面地址有误，或者该页面不存在。',
      button: '返回首页'
    }
  }
}
