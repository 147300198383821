export default {
  fixPlatform() {
    if (process.env.NODE_ENV === 'development') {
      return
    }
    // 平台、设备和操作系统
    var system = {
      win: false,
      mac: false,
      xll: false,
      ipad: false
    }
    // 检测平台
    var p = navigator.platform
    system.win = p.indexOf('Win') === 0
    system.mac = p.indexOf('Mac') === 0
    system.x11 = p === 'X11' || p.indexOf('Linux') === 0
    if (!system.win && !system.mac && !system.xll) {
      var currentUrl = window.location.href
      if (currentUrl.indexOf('huixinsky.com') !== -1) {
        // 替换 URL 中的 'www.' 为 'm.' 并进行跳转
        window.location.href = 'https://m.huixinsky.com'
      }
    }
  }
}
