export default {
  data: [
    { id: '1', title: '华录光存储研究院(大连)有限公司', url: '' },
    { id: '2', title: '中国计算机学会(CCF)', url: '' },
    { id: '3', title: '苏州互盟信息存储技术有限公司', url: '' },
    // { id: '4', title: '中移（苏州）软件技术有限公司', url: '' },
    { id: '4', title: '广西壮族自治区通信产业服务有限公司', url: '' },
    { id: '5', title: '北京易华录信息技术股份有限公司', url: '' }
    // { id: '7', title: '北京航天安网科技有限公司', url: '' }
  ]
}
