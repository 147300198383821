export default {
  type: [
    {
      id: '1',
      title: '软件产品',
      cover: require('@/assets/home/software.jpeg')
    },
    {
      id: '2',
      title: '硬件产品',
      cover: require('@/assets/home/hardware.jpeg')
    }
  ],
  data: [
    {
      id: '1',
      title: '数据资源一体化智能管理平台',
      titleEn: 'Storage management platform',
      cover: require('@/assets/product/product-cover-1.png'),
      image: require('@/assets/product/product-image-1.jpeg'),
      type: 'soft',
      content:
        '融合各类数据管理和数据存储技术，提供数据资源一体化管理平台，建立存算一体化和存算分离的一体化云服务体系，彻底解决数据孤岛和计算孤岛的问题。可信全国产，自主可控，完成数据管理IT资源的从存储到计算的国产化替代。',
      values: [
        { id: '1',
          title: '一体化',
          content: '数据管理一体化\n' + '存储和计算资源一体化'
        },
        { id: '2',
          title: '融合',
          content: '多云融合\n' + '存储介质(磁光电)融合\n' + '存储资源(SAN,HDFS,S3)融合\n' + '存储(OBS、OSS)和计算技术融合'
        },
        { id: '3',
          title: '智能化',
          content: '数据智能管理\n' + '平台智能运维'
        }
      ],
      cases: ['1'],
      isMajor: true
    },
    {
      id: '2',
      title: '磁光组合数据存储长期备份归档管理系统',
      titleEn: 'Backup Management',
      cover: require('@/assets/product/product-cover-2.png'),
      image: require('@/assets/product/product-image-2.png'),
      type: 'soft',
      content:
        '该系统在现有磁存储为主的系统中，无缝嵌入以蓝光光盘为主要存储介质的备份与归档策略，并应用蓝光介质寿命检测系统，确保蓝光介质的稳定性，以保证数据在长期存储中的完整性与安全性。该系统依靠磁盘系统与蓝光光盘系统在数据备份归档存储上的结合，利用离线点供技术完成对于数据的分级存储与管理，同时，对数据的长期安全存储，进行3个备份，分别存在2种物理介质上，其中1份是可以移动并可离线的永久备份。由此，磁光组合数据长期安全备份管理系统与数据安全存储3+2+1策略的结合，保证数据在长期存储过程中的安全性、可行性和可控性。',
      values: [
        { id: '1', title: '冷存节电', content: '蓝光光盘库，设备待机功耗60-100w。' },
        { id: '2', title: '安全高可靠', content: '蓝光光盘具有不可擦写特性，保证数据安全高可靠性。' }
      ],
      cases: ['2', '3']
    },
    {
      id: '3',
      title: '磁光组合双网物理隔离信息安全传输审计系统',
      titleEn: 'Secure transmission audit system',
      cover: require('@/assets/product/product-cover-3.png'),
      image: require('@/assets/product/product-image-3.png'),
      type: 'soft',
      content:
        '磁光组合双网物理隔离信息传输审计系统是一套基于磁光混合技术的完全物理隔离的自动化网间信息安全交互平台。该产品存在于内部网络（可以是涉密网）和外部网络（可以是互联网或非涉密网）之间，保障传输数据不受来自外部和内部用户的入侵和破坏，阻断各类病毒的传输通道和运行环境，保证数据传输的安全性，真实性，有效性和原始数据的不可更改的一种安全通道技术（第五代隔离技术）。并通过此技术手段实现内部网络与外部网络之间的数据安全传输的同时实现完全物理隔离环境的建立的同时完成三权、审计、记录、反馈、报警等工作。',
      values: [
        {
          id: '1',
          title: '完全符合“等保2.0”',
          content: '符合“等保2.0”三级安全评测要求：安全物理环境、安全区域边界、安全计算环境、安全管理中心。'
        },
        {
          id: '2',
          title: '合规合法',
          content: '符合《计算机信息系统联网保密管理规定》、《电子政务保密管理指南》、《信息安全技术网络安全等级保护要求》。'
        }
      ],
      cases: ['4', '5'],
      isMajor: true
    },
    {
      id: '4',
      title: '磁光组合教育管理系统',
      titleEn: 'Education management system',
      cover: require('@/assets/product/product-cover-4.png'),
      image: require('@/assets/product/product-image-4.jpg'),
      type: 'soft',
      content:
        '可通过整合教务系统、图书馆系统、后勤系统以及数据补录相关的教育数据，进行数据抽取、转换、加载、计算的质量管理，将相关数据写入数据仓库，并且输出到数据集市中进行数据建模；并可按照招生分析、教职工分析、学生成绩分析、校园行为分析、科研项目分析、图书馆分析等进行整合，实现基础科目的数据可视化展示，以及各类数据场景的探索式自助分析。'
    },
    {
      id: '5',
      cover: require('@/assets/hardware/hardware-1.jpeg'),
      image: require('@/assets/hardware/hardware-image-1.png'),
      type: 'hard',
      title: 'HX-HDA300蓝光光盘库',
      content:
        'HX-HDA300蓝光光盘库，采用42U机柜，各组成单元都采用标准化设计，可以安装于19英寸标准机柜中，蓝光单张碟片容量已经达到300GB。系统采用模块化标准设计，由光盘匣、基本单元、温数据扩展单元（含光驱组模块，可选）、冷数据扩展单元（不含光驱组模块）、机械手单元、光驱组模块、电源模块和管理服务器构成。用户可以根据数据存储容量和读写速度需求，进行基本单元、扩展单元、光盘匣、驱动器模块灵活组合配置，实现不同容量的数据存储和不同的读写性能。',
      html: '<ol class=" list-paddingleft-2"> <li> <p> <span style="font-size: 14px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">设备介绍：HX-HDA300蓝光光盘库，采用42U机柜，各组成单元都采用标准化设计，可以安装于19英寸标准机柜中，蓝光单张碟片容量已经达到300GB。系统采用模块化标准设计，由光盘匣、基本单元、温数据扩展单元（含光驱组模块，可选）、冷数据扩展单元（不含光驱组模块）、机械手单元、光驱组模块、电源模块和管理服务器构成。用户可以根据数据存储容量和读写速度需求，进行基本单元、扩展单元、光盘匣、驱动器模块灵活组合配置，实现不同容量的数据存储和不同的读写性能。</span> </p> </li> <li> <p> <span style="font-size: 14px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">设备组件介绍：HX-HDA300蓝光光盘库，采用42U机柜，单机柜最大拥有380个光盘匣槽位，单个光盘匣内含12张300GB AD-R蓝光碟片、单光盘匣裸容量达3.6TB,单个光盘库机柜存储裸容量为1.36PB。蓝光光盘库考虑到数据安全，支持盘匣内12张碟片间Raid功能，支持RAID0/5/6。</span> </p> </li> <li> <p> <span style="font-size: 14px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">适用领域：具有高可靠性的海量近线、离线存储能力，适合数据的长期和安全存储，一般适用在数据的归档或者长期备份、冷数据存储领域。</span> </p> </li></ol><p> <br/></p>'
    },
    {
      id: '6',
      cover: require('@/assets/hardware/hardware-2.jpeg'),
      image: require('@/assets/hardware/hardware-image-2.png'),
      type: 'hard',
      title: 'HX-HDA500蓝光光盘库',
      content:
        '提供数据近线存储与离线存储，采用蓝光光盘匣存储数据，单机柜最大拥有532个光盘匣槽位，单个光盘匣内含12张500GB BD-R蓝光碟片，单光盘匣裸容量达6.0TB，单个光盘库机柜存储裸容量可达3.192PB。考虑到数据安全，支持盘匣内12张碟片间RAID功能，支持RAID0/5/6。Raid6后，可以容许单个盘匣有任意两张光盘故障，也能够保证数据不会损坏。 系列采用模块化标准设计，由底部单元、基本单元、温数据扩展单元（含光驱组模块）、冷数据扩展单元（不含光驱组模块）、光驱组模块、光盘匣构成。可以根据数据存储容量和读写速度需求，进行基本单元、扩展单元、光盘匣、驱动器模块灵活组合配置，实现不同容量的数据存储和不同的读写速度。',
      html: '<ol class=" list-paddingleft-2"> <li> <p> 设备整体介绍：提供数据近线存储与离线存储，采用蓝光光盘匣存储数据，单机柜最大拥有532个光盘匣槽位，单个光盘匣内含12张500GB BD-R蓝光碟片，单光盘匣裸容量达6.0TB，单个光盘库机柜存储裸容量可达3.192PB。考虑到数据安全，支持盘匣内12张碟片间RAID功能，支持RAID0/5/6。Raid6后，可以容许单个盘匣有任意两张光盘故障，也能够保证数据不会损坏。 </p> <p> 系列采用模块化标准设计，由底部单元、基本单元、温数据扩展单元（含光驱组模块）、冷数据扩展单元（不含光驱组模块）、光驱组模块、光盘匣构成。可以根据数据存储容量和读写速度需求，进行基本单元、扩展单元、光盘匣、驱动器模块灵活组合配置，实现不同容量的数据存储和不同的读写速度。 </p> </li> <li> <p> 设备组件：HX-HDA500采用1套光驱组，共6个光驱模块实现蓝光光盘的数据读写，数据读取速度为540MB/S，数据写入速度为375MB/S。同时蓝光光盘库本着向下兼容的原则，支持1.2TB光盘匣（100G光盘）、3.6TB光盘匣（300G光盘）和6.0TB光盘匣（500G光盘），其中对于3.6TB的光盘匣，在配置6台光驱的情况下与6.0TB盘匣使用方式上无异，1.2TB在相同条件下（6台光驱）可以只读不写，在扩展到12台光驱的情况下依然能够正常读写，良好的体现了光存储设备持续向下兼容的特点。 </p> </li> <li> <p> 适用领域：具有高可靠性的海量近线、离线存储能力，适合数据的长期和安全存储，一般适用在数据的归档或者长期备份、冷数据存储领域。 </p> </li></ol><p> <br/></p>'
    /*
    },
    {
      id: '7',
      cover: require('@/assets/hardware/hardware-3.png'),
      type: 'hard',
      title: 'HX-HDL2300（10U机架设备）',
      content:
        '机械臂、光盘盒的自动校准，可配置12个光盘驱动器，4个可推拉式光盘匣仓，96个可替换的高密度光盘盒，可容纳1152张光盘，光盘盒具有唯一的RFID标识，支持企业级光驱和归档级光盘，尺寸（mm）：930长 448宽 445高',
      html: '机械臂、光盘盒的自动校准，可配置<strong>12</strong>个光盘驱动器，<strong>4</strong>个可推拉式光盘匣仓，<strong>96</strong>个可替换的高密度光盘盒，可容纳<strong>1152</strong>张光盘，光盘盒具有唯一的RFID标识，支持企业级光驱和归档级光盘，尺寸（mm）：930长 448宽 445高'
    },
    {
      id: '8',
      cover: require('@/assets/hardware/hardware-4.png'),
      type: 'hard',
      title: 'HX-HDL17280（45U机柜设备）',
      content:
        '机械臂、光盘盒的自动校准，可配置28个光盘驱动器，720个可替换的高密度光盘盒，可容纳8640张光盘，光盘盒具有唯一的RFID标识，支持企业级光驱和归档级光盘，冗余电源，安全指纹密码锁，支持光盘检测光驱，尺寸（mm）：1200长 600宽 2000高',
      html: '机械臂、光盘盒的自动校准，可配置<strong>28</strong>个光盘驱动器，<strong>720</strong>个可替换的高密度光盘盒，可容纳<strong>8640</strong>张光盘，光盘盒具有唯一的RFID标识，支持企业级光驱和归档级光盘，冗余电源，安全指纹密码锁，支持光盘检测光驱，尺寸（mm）：1200长 600宽 2000高'
    */
    }
  ]
}
